import { useState, useMemo, useCallback } from "react"

const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    if (typeof window === "undefined") {
      return defaultValue
    }

    try {
      const item = window.sessionStorage.getItem(key)
      if (item) {
        return JSON.parse(item)
      }

      window.sessionStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    } catch (err) {
      window.sessionStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    }
  })

  const setStoredValue = useCallback(
    (newValue) =>
      new Promise((resolve) => {
        try {
          setValue((prevValue) => {
            const next = typeof newValue === "function" ? newValue(prevValue) : newValue
            window.sessionStorage.setItem(key, JSON.stringify(next))
            resolve(next)
            return next
          })
        } catch (err) {
          resolve()
        }
      }),
    [key],
  )

  return useMemo(() => [value, setStoredValue], [value, setStoredValue])
}

export default useSessionStorage
